import Input from "components/Form/Input";
import { FormComponentProps } from "./FormComponent";

const NumberInput: React.FC<FormComponentProps> = ({
  flowParameter,
  form,
  ...rest
}) => {
  return (
    <Input
      type="number"
      leftEl={flowParameter.isSymbolLeft ? flowParameter.symbol : undefined}
      rightEl={flowParameter.isSymbolLeft ? undefined : flowParameter.symbol}
      {...form.register(flowParameter.id)}
      {...rest}
    />
  );
};

export default NumberInput;
