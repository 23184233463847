import { FlowParameter } from "constants/types";
import TextInput from "./TextInput";
import NumberInput from "./NumberInput";
import TextAreaInput from "./TextAreaInput";
import CreateDropdown from "./CreateDropdown";
import { FieldValues, UseFormReturn } from "react-hook-form";
import DropdownComponent from "./DropdownComponent";
import RadioInput from "./RadioInput";

export type FormComponentProps = {
  flowParameter: FlowParameter;
  required?: boolean;
  placeholder?: string;
  label: string;
  name: string;
  form: UseFormReturn<FieldValues, object>;
  errors: {
    [x: string]: any;
  };
};

const FormComponent: React.FC<FormComponentProps> = ({
  flowParameter,
  ...rest
}) => {
  switch (flowParameter.type) {
    case "TEXT":
      return <TextInput flowParameter={flowParameter} {...rest} />;
    case "NUMBER":
      return <NumberInput flowParameter={flowParameter} {...rest} />;
    case "TEXTAREA":
      return <TextAreaInput flowParameter={flowParameter} {...rest} />;
    case "CREATEDROPDOWN":
      return <CreateDropdown flowParameter={flowParameter} {...rest} />;
    case "DROPDOWN":
      return <DropdownComponent flowParameter={flowParameter} {...rest} />;
    case "RADIO":
      return <RadioInput flowParameter={flowParameter} {...rest} />;
    default:
      return null;
  }
};

export default FormComponent;
