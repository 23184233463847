import * as yup from "yup";
import { Dialog } from "components/Modal";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import GET_FLOW from "graphql/queries/getFlowParameters.graphql";
import CREATE_REQUEST from "graphql/mutations/createRequest.graphql";
import { FlowInstance, FlowParameter, FlowsConnection } from "constants/types";
import Button from "components/Button";
import FormComponent from "./FormComponents/FormComponent";
import { getValidationSchema } from "./FormComponents/validation";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useState } from "react";
import ProductInput from "components/Request/FormComponents/ProductInput";
import { Product } from "components/Request/FormComponents/ProductInput/ProductInput";
import { v4 } from "uuid";
import { sum } from "lodash";

type NewRequestDialogueProps = {
  isVisible: boolean;
  setIsVisible: (b: boolean) => void;
};

const NewRequestDialogue: React.FC<NewRequestDialogueProps> = ({
  isVisible,
  setIsVisible,
}) => {
  const [products, setProducts] = useState<Product[]>([{ id: v4() }]);

  const { data, loading } = useQuery<{ flows: FlowsConnection }>(GET_FLOW);

  const { push } = useRouter();
  const [createRequest, { loading: createLoading }] = useMutation<{
    createRequest: { flowInstance: FlowInstance };
  }>(CREATE_REQUEST, {
    onCompleted: (data) => {
      setIsVisible(false);
      push(`/requests/${data.createRequest.flowInstance.id}`);
    },
  });
  const salesDiscountFlow = data?.flows?.nodes[0];
  const flowParameters =
    salesDiscountFlow?.flowParameters.nodes.filter(
      (f): f is FlowParameter => !!f
    ) ?? [];

  const validationSchema = yup
    .object()
    .shape(getValidationSchema(flowParameters, products));

  const form = useForm({ resolver: yupResolver(validationSchema) });
  const errors = form.formState.errors;
  const { handleSubmit } = form;

  const onSubmit = handleSubmit((data) => {
    const parameterInput = Object.entries(data).filter(
      ([key]) => !key.includes("product-")
    );

    const totalPrice = sum(
      products.map((product: Product) =>
        product?.listPrice && product?.quantity
          ? product?.listPrice * product?.quantity
          : 0
      )
    );
    const totalDiscount = sum(
      products.map((product: Product) =>
        product?.listPrice && product?.quantity && product?.discountPercentage
          ? product?.listPrice *
            product?.quantity *
            (product.discountPercentage / 100)
          : 0
      )
    );

    const totalDiscountPercent = (totalDiscount / totalPrice) * 100;

    const totalSalesPrice = totalPrice - totalDiscount;

    const priceId = flowParameters.find(
      (parameter: FlowParameter) => parameter.name === "Total List Price"
    )?.id;
    const discountId = flowParameters.find(
      (parameter: FlowParameter) => parameter.name === "Total Discount %"
    )?.id;
    const discountAmountId = flowParameters.find(
      (parameter: FlowParameter) => parameter.name === "Total Discount Amount"
    )?.id;
    const salePriceId = flowParameters.find(
      (parameter: FlowParameter) => parameter.name === "Total Sales Price"
    )?.id;

    const parameters = [
      { flowParameterId: priceId, value: totalPrice.toString() },
      { flowParameterId: discountId, value: totalDiscountPercent.toString() },
      { flowParameterId: discountAmountId, value: totalDiscount.toString() },
      { flowParameterId: salePriceId, value: totalSalesPrice.toString() },
      ...parameterInput.map(([key, value]) => ({
        flowParameterId: key,
        value,
      })),
    ];

    const input = {
      flowId: salesDiscountFlow?.id,
      parameters,
      products: products.map((product: Product) => ({
        ...product,
        id: undefined,
      })),
    };
    createRequest({
      variables: { input },
    });
  });

  const notValid = data !== undefined && salesDiscountFlow?.isValid === false;

  return !loading ? (
    <Dialog
      visible={isVisible}
      onDismiss={() => setIsVisible(false)}
      className={classNames({ "h-auto": notValid })}
      noValidate
      onSubmit={onSubmit}
      isForm
    >
      <Dialog.Header>
        New {salesDiscountFlow ? salesDiscountFlow.name : "Sales Discount"}
      </Dialog.Header>
      {notValid ? (
        <>
          <Dialog.Content>
            <p>
              The Sales Discount authority flow is not valid!
              <br /> Make sure there are no steps without approvers.
            </p>
          </Dialog.Content>
          <Dialog.Footer>
            <Button onClick={() => setIsVisible(false)}>Close</Button>
          </Dialog.Footer>
        </>
      ) : (
        <>
          <Dialog.Content>
            {flowParameters
              .filter((parameter: FlowParameter) =>
                ["Account", "Account Type", "Notes"].includes(parameter.name)
              )
              .map((parameter: FlowParameter) => (
                <>
                  {parameter.name === "Notes" && (
                    <ProductInput
                      products={products}
                      setProducts={setProducts}
                      form={form}
                      errors={errors}
                    />
                  )}
                  <FormComponent
                    key={parameter.id}
                    flowParameter={parameter}
                    required={parameter.isRequired || undefined}
                    placeholder={parameter.placeholder || undefined}
                    label={parameter.name}
                    name={parameter.id}
                    form={form}
                    errors={errors}
                  />
                </>
              ))}
          </Dialog.Content>
          <Dialog.Footer>
            <Button type="submit" loading={loading || createLoading}>
              Submit
            </Button>
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  ) : null;
};

export default NewRequestDialogue;
