import { FormComponentProps } from "./FormComponent";
import ContorlledCreatableDropdown from "components/Dropdown/ControlledCreatableDropdown";
import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

const GET_PARAMETER_VALUES = gql`
  query getParameterValues($parameterId: UUID) {
    getParameterValues(parameterId: $parameterId) {
      nodes
    }
  }
`;

const CreateDropdown: React.FC<FormComponentProps> = ({
  flowParameter,
  form,
  ...rest
}) => {
  const { data, loading } = useQuery(GET_PARAMETER_VALUES, {
    variables: { parameterId: flowParameter.id },
  });

  const options = useMemo(
    () =>
      data?.getParameterValues.nodes.map((entry: string) => ({
        label: entry,
        value: entry,
      })),
    [data]
  );

  return (
    <ContorlledCreatableDropdown
      control={form.control}
      options={options}
      isLoading={loading}
      {...rest}
    />
  );
};

export default CreateDropdown;
