import Input from "components/Form/Input";
import { FormComponentProps } from "./FormComponent";

const TextInput: React.FC<FormComponentProps> = ({
  flowParameter,
  form,
  ...rest
}) => {
  return (
    <Input
      leftEl={flowParameter.isSymbolLeft && flowParameter.symbol}
      rightEl={!flowParameter.isSymbolLeft && flowParameter.symbol}
      {...form.register(flowParameter.id)}
      {...rest}
    />
  );
};

export default TextInput;
