import { gql, useQuery } from "@apollo/client";
import ContorlledCreatableDropdown from "components/Dropdown/ControlledCreatableDropdown";
import { Input } from "components/Form";
import React, { useEffect, useMemo } from "react";
import { X } from "react-feather";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Product } from "./ProductInput";

const GET_PRODUCT_NAMES = gql`
  query getProductNames {
    getProductNames {
      nodes
    }
  }
`;

type ProductItemProps = {
  product: Product;
  removeProduct: (product: Product) => void;
  form: UseFormReturn<FieldValues, object>;
  errors: {
    [x: string]: any;
  };
};

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  removeProduct,
  form,
  errors,
}) => {
  const { watch } = form;

  const name = watch(`product-${product.id}-name`);

  useEffect(() => {
    product.name = name?.value;
  }, [product, name]);

  const { data, loading } = useQuery(GET_PRODUCT_NAMES);

  const names = useMemo(
    () =>
      data?.getProductNames.nodes.map((entry: string) => ({
        label: entry,
        value: entry,
      })),
    [data]
  );

  return (
    <div className="flex">
      <div>
        <ContorlledCreatableDropdown
          label=""
          placeholder="Product"
          className="-mb-16 invalid:mb-0"
          name={`product-${product.id}-name`}
          control={form.control}
          options={names || []}
          isLoading={loading}
          required
        />
        <div className="flex max-w-[400px] gap-8">
          <Input
            leftEl="$"
            placeholder="List Price"
            label=""
            {...form.register(`product-${product.id}-listPrice`)}
            onChange={(e) => (product.listPrice = parseFloat(e.target.value))}
            errors={errors}
          />
          <Input
            placeholder="Quantity"
            label=""
            {...form.register(`product-${product.id}-quantity`)}
            onChange={(e) => (product.quantity = parseFloat(e.target.value))}
            errors={errors}
          />
          <Input
            rightEl="%"
            placeholder="Discount"
            label=""
            {...form.register(`product-${product.id}-discount`)}
            onChange={(e) =>
              (product.discountPercentage = parseFloat(e.target.value))
            }
            errors={errors}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center text-grey--50">
        <span
          className="mx-8 mb-16 cursor-pointer rounded-full bg-grey-25 p-4"
          onClick={() => removeProduct(product)}
        >
          <X size={16} />
        </span>
      </div>
    </div>
  );
};

export default ProductItem;
