import classNames from "classnames";
import ResourceLayoutBase from "./ResourceLayoutBase";

type ResourceLayoutProps = {
  resource: string;
  parentResource?: string;
  right?: React.ReactElement;
  children: React.ReactNode;
  tabElement?: React.ReactElement;
  sidebarElement?: React.ReactElement;
};

const ResourceLayout: React.FC<ResourceLayoutProps> = ({
  children,
  resource,
  parentResource,
  tabElement,
  sidebarElement,
  right,
}) => {
  const hasTabs = !!tabElement;
  const hasSidebar = !!sidebarElement;

  return (
    <ResourceLayoutBase
      right={right}
      parentResource={parentResource}
      resource={resource}
    >
      <div
        className={classNames(["h-full w-full flex-shrink flex-grow sm:p-8"])}
      >
        {tabElement}
        <div
          className={classNames([
            "bg-white",
            {
              // tab height is 48px
              "h-[calc(100%_-_48px)]": hasTabs,
              "h-full": !hasTabs,
            },
          ])}
        >
          {children}
        </div>
      </div>
      {hasSidebar && (
        <div className="hidden h-full flex-shrink-0 flex-grow-0 bg-grey-5 sm:block">
          {sidebarElement}
        </div>
      )}
    </ResourceLayoutBase>
  );
};

export default ResourceLayout;
