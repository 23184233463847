import { Radio, SelectionList } from "components/Form";
import Label from "components/Form/Label";
import { FormComponentProps } from "./FormComponent";

const RadioInput: React.FC<FormComponentProps> = ({
  flowParameter,
  form,
  label,
  ...rest
}) => {
  return (
    <Label label={label}>
      <SelectionList
        orientation="vertical"
        className="mb-16 gap-y-2"
        {...form.register(flowParameter.id)}
        {...rest}
      >
        {flowParameter?.options?.map((option, index) => (
          <Radio key={option} value={option || ""} defaultChecked={index === 0}>
            {option}
          </Radio>
        ))}
      </SelectionList>
    </Label>
  );
};

export default RadioInput;
