import Dropdown from "components/Dropdown/ControlledDropdown";
import { FormComponentProps } from "./FormComponent";

const getOption = (option: string) => ({ value: option });

const DropdownComponent: React.FC<FormComponentProps> = ({
  flowParameter,
  form,
  ...rest
}) => {
  return (
    <Dropdown
      options={
        flowParameter.options?.map((option) => getOption(option || "")) || []
      }
      getOptionLabel={(option) => option.value}
      getOptionValue={(option) => option.value}
      control={form.control}
      defaultValue={getOption(flowParameter.options?.[0] || "")}
      {...rest}
    />
  );
};

export default DropdownComponent;
