import NavLayout from "components/Layout/NavLayout";
import Head from "next/head";
import Header from "./Header";
import { APP_NAME } from "constants/strings";
import classNames from "classnames";

type ResourceLayoutBaseProps = {
  resource: string;
  parentResource?: string;
  right?: React.ReactElement;
  children: React.ReactNode;
};

const ResourceLayoutBase: React.FC<ResourceLayoutBaseProps> = ({
  children,
  resource,
  parentResource,
  right,
}) => {
  return (
    <>
      <Head>
        <title>
          {resource} - {APP_NAME}
        </title>
      </Head>
      <NavLayout current={resource}>
        <div className="h-full w-full">
          <div className="h-full w-full">
            <Header right={right} title={{ resource, parentResource }} />
            <div
              className={classNames([
                "flex h-[calc(100%_-_64px)] w-full overflow-y-auto",
              ])}
            >
              {children}
            </div>
          </div>
        </div>
      </NavLayout>
    </>
  );
};

export default ResourceLayoutBase;
