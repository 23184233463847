import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Bell, Plus } from "react-feather";

import GET_CURRENT_USER from "graphql/queries/getCurrentUser.graphql";

import Button from "components/Button";
import NewRequestDialogue from "components/Request/NewRequestDialogue";
import AlertPopover from "components/Alert/AlertPopover";
import useHasAlert from "hooks/useHasAlert";

const CommonHeaderButtons = () => {
  const [isNewRequestDialogueVisible, setIsNewRequestDialogueVisible] =
    useState(false);

  const [isPopoverVisible, setIsPopoverVisbile] = useState(false);

  const { data } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "cache-and-network",
  });

  const hasAlert = useHasAlert({ unseenAlertExists: data?.unseenAlertExists });

  const onClose = () => setIsPopoverVisbile(false);

  return (
    <div className="flex gap-x-16">
      {isPopoverVisible && <AlertPopover onClose={onClose} />}
      <button
        onClick={() => setIsPopoverVisbile(true)}
        className="relative text-blue-100 md:hidden"
        title="Alerts"
      >
        <Bell size={16} />
        {hasAlert && (
          <div style={{ top: "-1px", left: "8px" }} className="absolute">
            <span
              style={{ width: "8px", height: "8px" }}
              className="inline-block rounded-full bg-red-100"
            />
          </div>
        )}
      </button>
      <Button
        size="REGULAR"
        variant="PRIMARY"
        mode="BOLD"
        icon={<Plus size="16" />}
        className="hidden rounded-full !px-8 sm:block sm:rounded-xlarge sm:!px-16"
        onClick={() => setIsNewRequestDialogueVisible(true)}
      >
        New Request
      </Button>
      <Button
        size="REGULAR"
        variant="PRIMARY"
        mode="BOLD"
        icon={<Plus size="16" />}
        className="block rounded-full !px-8 sm:hidden sm:rounded-xlarge sm:!px-16"
        onClick={() => setIsNewRequestDialogueVisible(true)}
      ></Button>
      {isNewRequestDialogueVisible && (
        <NewRequestDialogue
          isVisible={isNewRequestDialogueVisible}
          setIsVisible={setIsNewRequestDialogueVisible}
        />
      )}
    </div>
  );
};

export default CommonHeaderButtons;
