import Button from "components/Button";
import Label from "components/Form/Label";
import ProductItem from "./ProductItem";
import { v4 } from "uuid";
import { FieldValues, UseFormReturn } from "react-hook-form";

export type Product = {
  id: string;
  name?: string;
  listPrice?: number;
  quantity?: number;
  discountPercentage?: number;
};

type ProductInputProps = {
  products: Array<Product>;
  setProducts: (products: Array<Product>) => void;
  form: UseFormReturn<FieldValues, object>;
  errors: {
    [x: string]: any;
  };
};

const ProductInput: React.FC<ProductInputProps> = ({
  products,
  setProducts,
  form,
  errors,
}) => {
  const removeProduct = (product: Product) => {
    setProducts(
      products.filter((compareProduct) => compareProduct.id !== product.id)
    );
  };

  return (
    <>
      <Label label="Deal Breakdown" required>
        {products &&
          products.map((product) => (
            <ProductItem
              key={product.id}
              product={product}
              removeProduct={removeProduct}
              form={form}
              errors={errors}
            />
          ))}
        <Button
          variant="TEXT"
          onClick={() => setProducts([...products, { id: v4() }])}
          className="mb-16"
        >
          Add Another Product
        </Button>
      </Label>
    </>
  );
};

export default ProductInput;
