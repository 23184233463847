import { FlowParameter } from "constants/types";
import * as yup from "yup";
import { Product } from "./ProductInput/ProductInput";

type ValidationSchema = {
  [name: string]: any;
};

const getParameterValidation = (flowParameter: FlowParameter) => {
  if (flowParameter.isRequired) {
    switch (flowParameter.type) {
      case "NUMBER":
        return yup
          .string()
          .trim()
          .required(`"${flowParameter.name}" cannot be empty`);
      case "TEXT":
      case "TEXTAREA":
        return yup
          .string()
          .trim()
          .required(`"${flowParameter.name}" cannot be empty`);
      case "CREATEDROPDOWN":
      case "DROPDOWN":
        return yup
          .mixed()
          .transform((value: { value: string }) => {
            return value?.value;
          })
          .required(`"${flowParameter.name}" cannot be empty`);
    }
  } else {
    switch (flowParameter.type) {
      case "NUMBER":
        return yup.number().nullable().notRequired();
      case "TEXT":
      case "TEXTAREA":
        return yup.string().trim().nullable().notRequired();
      case "CREATEDROPDOWN":
      case "DROPDOWN":
        return yup
          .mixed()
          .transform((value: { value: string }) => {
            return value?.value;
          })
          .nullable()
          .notRequired();
    }
  }
  return undefined;
};

const numberValidation = (name: string) =>
  yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    )
    .required(`${name} cannot be empty`)
    .typeError(`${name} must be a number`);

export const getValidationSchema = (
  flowParameters: Array<FlowParameter>,
  products: Array<Product>
) => {
  const schema: ValidationSchema = {};
  flowParameters.forEach((parameter) => {
    schema[parameter.id] = getParameterValidation(parameter);
  });

  products.map((product) => {
    schema[`product-${product.id}-name`] = yup
      .mixed()
      .transform((value: { value: string }) => {
        return value?.value;
      })
      .required(`"Name cannot be empty`);

    schema[`product-${product.id}-listPrice`] = numberValidation("List price");
    schema[`product-${product.id}-quantity`] = numberValidation("Quantity");
    schema[`product-${product.id}-discount`] = numberValidation(
      "Discount percentage"
    );
  });
  return schema;
};
