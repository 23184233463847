import TextArea from "components/Form/TextArea";
import { FormComponentProps } from "./FormComponent";

const TextAreaInput: React.FC<FormComponentProps> = ({
  flowParameter,
  form,
  ...rest
}) => {
  return <TextArea rows={3} {...rest} {...form.register(flowParameter.id)} />;
};

export default TextAreaInput;
