import { ArrowLeft, ChevronRight } from "react-feather";
import classNames from "classnames";
import Link from "next/link";
import Image from "next/image";

import { useRouter } from "next/router";
type HeaderProps = {
  title: {
    resource: string;
    parentResource?: string;
  };
  right?: React.ReactElement;
};

const Header: React.FC<HeaderProps> = ({ title, right }) => {
  const { resource, parentResource } = title;

  const { push } = useRouter();

  const handleBackClick = () => {
    if (parentResource === "Queue") {
      push("/");
    } else {
      push(`/${parentResource?.toLowerCase()}`);
    }
  };

  const headerTitle = parentResource ? (
    <div className="flex items-center gap-x-16">
      <span className="hidden font-light md:inline">{parentResource}</span>
      <div aria-hidden className="hidden md:inline">
        <ChevronRight size={24} />
      </div>
      <p className="truncate">{resource}</p>
    </div>
  ) : (
    resource
  );

  return (
    <div className="flex h-[64px] justify-between bg-white px-16">
      <div className="flex min-w-0 items-center gap-x-16 sm:gap-x-24">
        {parentResource ? (
          <div className="flex h-full items-center">
            <button
              onClick={handleBackClick}
              className="flex h-32 w-32 min-w-[32px] items-center justify-center rounded-full bg-dark-blue-100"
            >
              <ArrowLeft size="16" color="white" />
            </button>
          </div>
        ) : (
          <div className="flex h-full items-center md:hidden">
            <Link href="/">
              <a className="h-32">
                <Image
                  src="/oxley-logo-with-background.svg"
                  alt=""
                  height="32px"
                  width="32px"
                />
              </a>
            </Link>
          </div>
        )}
        <div
          className={classNames("min-w-0 font-medium text-grey--50", {
            "md:ml-8": !parentResource,
          })}
        >
          {headerTitle}
        </div>
      </div>
      <div className="flex min-w-fit items-center">{right}</div>
    </div>
  );
};

export default Header;
